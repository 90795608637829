import React from 'react';
import { Queue as QueueModule } from '@artroomai/creator';
import { Helmet } from 'react-helmet';
import { Header } from '../../components';

export const Queue: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Queue | Artroom AI</title>
			</Helmet>
			<Header />
			<QueueModule headerHeight="58px" />
		</>
	);
};
