import React, { PropsWithChildren } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAuth } from 'oidc-react';

dayjs.extend(utc);

export const ProtectedArea: React.FC<PropsWithChildren> = ({ children }) => {
	const { userData } = useAuth();

	return <>{userData ? children : null}</>;
};
