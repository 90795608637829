export const Checkbox = {
	baseStyle: {
		icon: {
			color: '#29AAE1',
		},
		control: {
			borderColor: '#29AAE1',
			borderRadius: 'base',
			_disabled: {
				borderColor: 'gray.300',
				bg: 'gray.200',
			},
			_checked: {
				background: 'transparent',
				borderColor: '#29AAE1',
				_hover: {
					background: 'transparent',
					borderColor: '#29AAE1',
				},
			},
		},
	},
};
