import React from 'react';
import { MenuItem, Box, Link } from '@chakra-ui/react';

interface IMenuLinkProps {
	icon: JSX.Element;
	href: string;
	title: string;
}

export const MenuLink: React.FC<IMenuLinkProps> = ({ href, icon, title }) => (
	<MenuItem
		bg="transparent"
		borderColor="defaultGray"
		borderRadius="10px"
		p="10px 0 10px 10px"
		height="40px"
		as={Link}
		icon={<Box w="25px">{icon}</Box>}
		color="white"
		fontWeight={700}
		mb="5px"
		href={href}
		_hover={{
			bg: '#161a31',
		}}>
		{title}
	</MenuItem>
);
