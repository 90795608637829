import React from 'react';
import { Center, Text, Divider } from '@chakra-ui/react';

export const NotFound: React.FC = () => {
	return (
		<Center w="100%" h="100vh" flexDirection="column">
			<Center>
				<Text fontSize="24px">404</Text>
				<Divider orientation="vertical" mx="16px" />
				<Text fontSize="24px">This page could not be found.</Text>
			</Center>
		</Center>
	);
};
