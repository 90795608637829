import React from 'react';
import { HStack, Avatar, Box, Text } from '@chakra-ui/react';
import { getPictureUrl } from '../../../../utils';

interface IBalanceAndNameShowProps {
	firstName?: string;
	lastName?: string;
	balance: number;
	avatarImage?: string;
}

export const BalanceAndNameShow: React.FC<IBalanceAndNameShowProps> = ({
	balance,
	avatarImage,
	firstName,
	lastName,
}) => {
	return (
		<HStack spacing="16px">
			<Box>
				<Text
					color="white"
					fontWeight={700}
					fontSize="14px"
					lineHeight="21px"
					noOfLines={1}>
					{`${firstName || ''} ${lastName || ''}`}
				</Text>
				<Text
					color="white"
					fontSize="14px"
					lineHeight="21px"
					noOfLines={1}>
					Shards: {balance}
				</Text>
			</Box>
			<Avatar
				src={getPictureUrl(avatarImage)}
				ignoreFallback={true}
				boxSize="40px"
			/>
		</HStack>
	);
};
