/* eslint-disable indent */
import React from 'react';
import {
	Avatar,
	Box,
	Button,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { getPictureUrl } from '../../../../utils';
import { MenuLink } from './MenuLink';
import { ReactComponent as User } from '../../../../assets/user.svg';
import { ReactComponent as LogOut } from '../../../../assets/log-out.svg';
import { ReactComponent as Cog } from '../../../../assets/cog.svg';
import { ReactComponent as CreditCard } from '../../../../assets/credit-card.svg';
import { useAuth } from 'oidc-react';
import { UserInfo } from '../../../../types';

interface IProfileMenuProps {
	balance: number;
	userInfo?: UserInfo;
}

export const ProfileMenu: React.FC<IProfileMenuProps> = ({
	balance,
	userInfo,
}) => {
	const { signOutRedirect } = useAuth();

	return (
		<Menu>
			<MenuButton as={Button} rounded={'full'} variant={'link'} minW={0}>
				<HStack spacing="16px">
					<Box>
						<Text
							color="white"
							fontWeight={700}
							textAlign="right"
							fontSize="14px"
							lineHeight="21px"
							noOfLines={1}>
							{`${userInfo?.firstName || ''} ${
								userInfo?.lastName || ''
							}`}
						</Text>
						<Text
							color="white"
							fontSize="14px"
							lineHeight="21px"
							noOfLines={1}>
							Shards: {balance}
						</Text>
					</Box>
					<Avatar
						src={getPictureUrl(userInfo?.pictureStoragePath)}
						ignoreFallback={true}
						boxSize="40px"
					/>
				</HStack>
			</MenuButton>
			<MenuList
				minW="290px"
				zIndex={2000}
				alignItems={'center'}
				bg="#0D1124"
				border="1px solid #545D7E"
				p="20px"
				borderRadius="10px">
				<HStack spacing="10px" mb="20px">
					<Avatar
						src={getPictureUrl(userInfo?.pictureStoragePath)}
						ignoreFallback={true}
						boxSize="52px"
					/>
					<Box>
						<Text
							fontSize="16px"
							fontWeight={700}
							lineHeight="19px"
							color="white">{`${userInfo?.firstName || ''} ${
							userInfo?.lastName || ''
						}`}</Text>
						<Text fontSize="12px" fontWeight={700} color="#545D7E">
							{userInfo?.userName || ''}
						</Text>
					</Box>
				</HStack>
				<MenuLink
					href={`${
						import.meta.env.VITE_MAIN_WEBSITE_BASE_URL
					}my-profile`}
					icon={<User />}
					title="My Profile"
				/>
				<MenuLink
					href={`${
						import.meta.env.VITE_MAIN_WEBSITE_BASE_URL
					}my-subscription`}
					icon={<CreditCard />}
					title="My Subscription"
				/>
				<MenuLink
					href={`${
						import.meta.env.VITE_MAIN_WEBSITE_BASE_URL
					}settings`}
					icon={<Cog />}
					title="Settings"
				/>

				<MenuItem
					onClick={() =>
						signOutRedirect({
							extraQueryParams: {
								/*returnUrl: `${
                                    import.meta.env.VITE_OIDC_AUTHORITY
                                }/account/login?returnUrl=${encodeURI(
                                    window.location.href,
                                )}`,*/
								returnUrl: `${
									import.meta.env.VITE_MAIN_WEBSITE_BASE_URL
								}`,
							},
						})
					}
					p="10px 0 10px 10px"
					height="40px"
					borderRadius="10px"
					fontWeight={700}
					icon={
						<Box w="25px">
							<LogOut />
						</Box>
					}
					color="white"
					bg="transparent"
					_hover={{ bg: '#161a31' }}>
					Log out
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
