import React from 'react';
import { Box } from '@chakra-ui/react';
import { DesktopHeader, MobileHeader } from './components';
import { useProfileCurrentData } from '../../api/queryClient';

export const Header: React.FC = () => {
	const { data } = useProfileCurrentData();

	return (
		<Box position="relative">
			<DesktopHeader userInfo={data?.value} />
			<MobileHeader userInfo={data?.value} />
		</Box>
	);
};
