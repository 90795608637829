const variantOutlined = () => ({
	field: {
		_focus: {
			borderColor: 'var(--chakra-colors-lightPrimary-400)',
			boxShadow: '0 0 0 1px var(--chakra-colors-lightPrimary-400)',
		},
	},
});

const variantFilled = () => ({
	field: {
		_focus: {
			borderColor: 'var(--chakra-colors-lightPrimary-400)',
			boxShadow: '0 0 0 1px var(--chakra-colors-lightPrimary-400)',
		},
	},
});

const variantFlushed = () => ({
	field: {
		_focus: {
			borderColor: 'var(--chakra-colors-lightPrimary-400)',
			boxShadow: '0 1px 0 0 var(--chakra-colors-lightPrimary-400)',
		},
	},
});

export const Input = {
	baseStyle: {},
	sizes: {},
	variants: {
		outline: variantOutlined,
		filled: variantFilled,
		flushed: variantFlushed,
	},
	defaultProps: {},
};
