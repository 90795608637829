import axios from 'axios';
import { userManager } from '../utils';
import { byPassInterceptionRegExp } from './by-pass-interception-routes';

export default function interceptor(): void {
	axios.defaults.baseURL =
		import.meta.env.VITE_BASE_URL || 'http://localhost:3000';

	axios.interceptors.request.use(async req => {
		const user = await userManager.getUser();

		const token = user?.access_token;

		if (!user) {
			const settings = userManager.settings;
			await userManager.signinRedirect(settings);
		}

		if (token) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${token}`;
			} else {
				delete req.headers['Authorization'];
			}
		}

		return req;
	});

	axios.interceptors.response.use(undefined, async err => {
		const { config, response } = err;
		if (response && (response.status === 403 || response.status === 401)) {
			if (config?.url && !byPassInterceptionRegExp.test(config?.url)) {
				await userManager.removeUser();
				await userManager.signoutRedirect({
					extraQueryParams: {
						returnUrl: `${
							import.meta.env.VITE_OIDC_AUTHORITY
						}/account/login?returnUrl=${encodeURI(
							window.location.href,
						)}`,
					},
				});
			}
		}
		return Promise.reject(err);
	});
}
