import React, { useState } from 'react';
import { Flex, Link as ChakraLink, Image, IconButton } from '@chakra-ui/react';
import { HiMenuAlt2 } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { Notifications } from '@artroomai/creator';
import { useRecoilValue } from 'recoil';
import Logo from '../../../../assets/Logo.svg';
import { BalanceAndNameShow } from './BalanceAndNameShow';
import { MobileMenu } from './MobileMenu';
import { shardsBalanceAtom } from '../../../../atoms/atoms';
import { UserInfo } from '../../../../types';

interface IMobileHeaderProps {
	userInfo?: UserInfo;
}

export const MobileHeader: React.FC<IMobileHeaderProps> = ({ userInfo }) => {
	const [show, setShow] = useState(false);

	const handleToggle = () => setShow(!show);

	const shardsBalance = useRecoilValue(shardsBalanceAtom);

	return (
		<Flex
			display={{ base: 'flex', md: 'none' }}
			alignItems="center"
			justifyContent="space-between"
			w="100%"
			height="80px"
			px={4}>
			<ChakraLink
				href={`${import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}`}
				flexShrink={0}>
				<Image boxSize="50px" src={Logo} />
			</ChakraLink>
			<Flex gap="10px">
				<Notifications />
				<BalanceAndNameShow
					balance={shardsBalance.Balance}
					avatarImage={userInfo?.pictureStoragePath}
					firstName={userInfo?.firstName}
					lastName={userInfo?.lastName}
				/>
				<IconButton
					bg="rgba(85, 91, 123, 0.4)"
					borderRadius="10px"
					aria-label="menu icon"
					icon={show ? <MdClose /> : <HiMenuAlt2 />}
					onClick={handleToggle}
					border="1px solid"
					sx={{
						borderImageSource:
							'linear-gradient(139.47deg, #555B7B -40.83%, rgba(85, 91, 123, 0) 105.51%)',
					}}
				/>
			</Flex>
			<MobileMenu show={show} />
		</Flex>
	);
};
