export const Button = {
	baseStyle: {
		borderRadius: '20',
	},
	variants: {
		solid: {
			color: 'white',
			bg: 'buttonPrimary',
			_selected: {
				bg: 'yellow.400',
			},
		},
		outline: {
			borderColor: 'buttonPrimary',
			color: 'buttonPrimary',
			_hover: {
				color: 'white',
			},
		},
		tabOutline: {
			color: 'white',
			border: '1px solid #555B7B',
			_hover: {
				backgroundColor: '#555B7B',
			},
		},
		ghost: {
			_hover: {
				color: 'white',
			},
		},
		tool: {
			width: '50px',
			height: '40px',
			color: 'white',
			bg: '#11182B',
			borderRadius: '4px',
			_hover: {
				bg: '#1b2a52',
			},
			_selected: {
				bg: 'lightPrimary.500',
			},
		},
		secondary: {
			bg: 'buttonOrange',
			color: 'white',
			_hover: {
				bg: '#ed9a1d',
			},
		},
	},
	defaultProps: {
		size: 'md',
		colorScheme: 'darkPrimary',
	},
};
