import React from 'react';
import { ImageViewer as ImageViewerModule } from '@artroomai/creator';
import { Helmet } from 'react-helmet';
import { Header } from '../../components';

export const ImageViewer: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Image Viewer | Artroom AI</title>
			</Helmet>
			<Header />
			<ImageViewerModule headerHeight="58px" />
		</>
	);
};
