import { QueryClient, useQuery } from 'react-query';
import { ApiResponse, UserInfo } from '../types';
import API from './api.base';

export const queryClient = new QueryClient();

export const useProfileCurrentData = () => {
	return useQuery<ApiResponse<UserInfo>>(
		'settings',
		() => API.get('/api/creator/profile/current', {}),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		},
	);
};
