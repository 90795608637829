import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useUserManager } from './common/hooks';

import {
	Generator,
	Queue,
	ImageViewer,
	NotFound,
	Painter,
	SuccessPlaceholder,
} from './pages';
import { CreatorProvider } from '@artroomai/creator';
import API from './api/api.base';
import { useSetRecoilState } from 'recoil';
import * as atom from './atoms/atoms';
import { queryClient, useProfileCurrentData } from './api/queryClient';
import { Playground } from './pages/Playground/Playground';

const App: React.FC = () => {
	const { token } = useUserManager();
	const setShardsBalance = useSetRecoilState(atom.shardsBalanceAtom);

	useEffect(() => {
		if (localStorage.getItem('chakra-ui-color-mode') === 'light') {
			localStorage.setItem('chakra-ui-color-mode', 'dark');
		}
	}, []);

	const { data } = useProfileCurrentData();

	return (
		<CreatorProvider
			api={API}
			token={token}
			socketURL={import.meta.env.VITE_SOCKET_HUB}
			setShardsBalance={setShardsBalance}
			queryClient={queryClient}
			isOver18Years={!!data?.value.isOver18Years}
			websiteURL={import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}
			staticURL={import.meta.env.VITE_PUBLIC_STATIC_BASE_URL}>
			<Routes>
				<Route
					path="*"
					element={<Navigate to="/404" replace={true} />}
				/>
				<Route
					path="/"
					element={<Navigate to="/generator" replace={true} />}
				/>
				<Route path="/generator" element={<Generator />} />
				<Route path="/painter" element={<Painter />} />
				<Route path="/queue" element={<Queue />} />
				<Route path="/image-viewer" element={<ImageViewer />} />
				{/* <Route path="/playground" element={<Playground />} /> */}
				<Route path="/success" element={<SuccessPlaceholder />} />
				<Route path="/404" element={<NotFound />} />
			</Routes>
		</CreatorProvider>
	);
};

export default App;
