import { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { userManager } from '../../utils';
import { ProtectedArea } from './components/ProtectedArea';
import { OIDC_CONFIG } from '../../constants';
import { useNavigate } from 'react-router-dom';

export const OidcAuth: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();

	const oidcConfig: AuthProviderProps = {
		...OIDC_CONFIG,
		onSignIn: user => {
			navigate('/generator');
		},
		// onSignOut: () => {
		// 	// userManager.signoutRedirect(userManager.settings).catch(e => {
		// 	// 	console.log(e, 'logout error');
		// 	// 	// location.href = import.meta.env.VITE_OIDC_LOGOUT_URL;
		// 	// });
		// 	console.log('SIGN-OUT');
		// },
		autoSignOutArgs: {
			extraQueryParams: {
				returnUrl: window.location.href,
			}
		},
		autoSignOut: true,
	};

	return (
		<AuthProvider {...oidcConfig} userManager={userManager}>
			<ProtectedArea>{children}</ProtectedArea>
		</AuthProvider>
	);
};
