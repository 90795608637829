import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../../components';
import { Generator as GeneratorModule } from '@artroomai/creator';

export const Generator: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Generator | Artroom AI</title>
			</Helmet>
			<Header />
			<GeneratorModule headerHeight="58px" />
		</>
	);
};
