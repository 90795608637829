import React from 'react';
import { Painter as PainterModule } from '@artroomai/creator';
import { Helmet } from 'react-helmet';
import { Header } from '../../components';

export const Painter: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Paint | Artroom AI</title>
			</Helmet>
			<Header />
			<PainterModule headerHeight="58px" />
		</>
	);
};
