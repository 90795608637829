import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { theme } from './theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './api/queryClient';
import { OidcAuth } from './components';
import '@fontsource/urbanist/600.css';
import '@fontsource/urbanist/700.css';
import '@fontsource/urbanist/900.css';

const extendedTheme = extendTheme(theme);

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'Development') {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DCN,
		integrations: [new BrowserTracing()],
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<BrowserRouter>
		<OidcAuth>
			<ChakraProvider
				theme={extendedTheme}
				toastOptions={{ defaultOptions: { isClosable: true } }}>
				<ColorModeScript />
				<QueryClientProvider client={queryClient}>
					<RecoilRoot>
						<App />
					</RecoilRoot>
				</QueryClientProvider>
			</ChakraProvider>
		</OidcAuth>
	</BrowserRouter>,
);
