export const colors = {
	background: '#080B16',
	buttonPrimary: '#29AAE1',
	buttonOrange: '#FAAF3B',
	textActive: '#555B7B',
	darkBlue2: '#0D1124',
	darkBlue3: '#12172F',
	darkBlue4: '#212543',
	lightPrimary: {
		50: '#93d4f0',
		100: '#7eccec',
		200: '#69c3e9',
		300: '#53bbe6',
		400: '#3eb2e3',
		500: '#29AAE1',
		600: '#1d9cd1',
		700: '#1a8aba',
		800: '#1679a3',
		900: '#13688b',
	},
	darkPrimary: {
		50: '#318bf7',
		100: '#0974f4',
		200: '#0760cb',
		300: '#064da2',
		400: '#043979',
		500: '#032650',
		600: '#022248',
		700: '#021e40',
		800: '#021a38',
		900: '#011630',
	},
};
