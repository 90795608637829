export const Progress = {
	parts: ['track', 'filledTrack'],
	baseStyle: {
		track: {
			bgColor: '#555B7B',
			borderRadius: '12px',
		},
		filledTrack: {
			borderRadius: '12px',
			bgColor: '#29AAE1',
		},
	},
	defaultProps: {
		borderRadius: '12px',
	},
};
