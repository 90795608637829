import React from 'react';
import {
	Flex,
	HStack,
	Image,
	IconButton,
	Tooltip,
	Link as ChakraLink,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { FaDiscord } from 'react-icons/fa';
import { useRecoilValue } from 'recoil';
import { Notifications } from '@artroomai/creator';
import { desktopLinks } from '../../../../constants';
import Logo from '../../../../assets/Logo.svg';
import { ActionItem } from './ActionItem';
import { ProfileMenu } from './ProfileMenu';
import { shardsBalanceAtom } from '../../../../atoms/atoms';
import { ReactComponent as QuestionIcon } from '../../../../assets/question.svg';
import { UserInfo } from '../../../../types';

interface IDesktopHeaderProps {
	userInfo?: UserInfo;
}

export const DesktopHeader: React.FC<IDesktopHeaderProps> = ({ userInfo }) => {
	const shardsBalance = useRecoilValue(shardsBalanceAtom);

	const location = useLocation();

	return (
		<Flex
			px="20px"
			py="4px"
			w="100%"
			position="relative"
			zIndex={10}
			bg="#0D1124"
			align="center"
			display={{ base: 'none', md: 'flex' }}
			justify="space-between">
			<HStack>
				<ChakraLink
					href={`${import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}`}>
					<Image boxSize="50px" src={Logo} mr="30px" />
				</ChakraLink>
				<HStack>
					{desktopLinks.map((elem, index) => (
						<Tooltip
							key={index}
							bg="textActive"
							color="white"
							label={elem.name}>
							<IconButton
								width="50px"
								height="44px"
								borderRadius="4px"
								aria-label={elem.name}
								as={Link}
								to={elem.href}
								color="white"
								bg={
									location.pathname === elem.href
										? 'buttonPrimary'
										: 'transparent'
								}>
								{elem.icon}
							</IconButton>
						</Tooltip>
					))}
				</HStack>
			</HStack>
			<HStack spacing="36px">
				{/* <TutorialTrigger /> */}
				<ActionItem
					icon={FaDiscord}
					title="Join Discord"
					url="https://discord.gg/XNEmesgTFy"
					isExternal={true}
					iconProps={{
						color: '#508EF8',
						height: '15px',
						width: '15px',
					}}
				/>
				<ActionItem
					// @ts-ignore
					icon={QuestionIcon}
					title="Tutorial"
					url="https://artroomai.gitbook.io/tutorials/artroom-basics/overview"
					isExternal={true}
				/>
				<Notifications />
				<ProfileMenu
					balance={shardsBalance.Balance}
					userInfo={userInfo}
				/>
			</HStack>
		</Flex>
	);
};
