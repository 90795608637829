import { ReactComponent as GenerateIcon } from '../assets/generate.svg';
import { ReactComponent as PaintIcon } from '../assets/paint.svg';
import { ReactComponent as QueueIcon } from '../assets/queue.svg';
import { ReactComponent as TransformIcon } from '../assets/merge-models.svg';
import { ReactComponent as UpscaleIcon } from '../assets/upscale.svg';
import { ReactComponent as ImageViewerIcon } from '../assets/image-viewer.svg';
import { ReactComponent as User } from '../assets/user.svg';
import { ReactComponent as Cog } from '../assets/cog.svg';
import { ReactComponent as CreditCard } from '../assets/credit-card.svg';

export const desktopLinks = [
	{
		name: 'Create',
		href: '/generator',
		icon: <GenerateIcon />,
	},
	{
		name: 'Paint',
		href: '/painter',
		icon: <PaintIcon />,
	},
	{
		name: 'Queue',
		href: '/queue',
		icon: <QueueIcon />,
	},
	{
		name: 'Image Viewer',
		href: '/image-viewer',
		icon: <ImageViewerIcon />,
	},
];

export const mobileLinks = [
	{
		name: 'Create',
		icon: <GenerateIcon />,
		href: '/generator',
	},
	{
		name: 'Image Viewer',
		icon: <ImageViewerIcon />,
		href: '/image-viewer',
	},
	{
		name: 'Paint (Desktop Only)',
		icon: <PaintIcon />,
		isDisabled: true,
	},
	{
		name: 'Queue',
		icon: <QueueIcon />,
		href: '/queue',
	},
	{
		name: 'My Profile',
		icon: <User />,
		isExternal: true,
		href: `${import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}my-profile`,
	},
	{
		name: 'My Subscription',
		icon: <CreditCard />,
		isExternal: true,
		href: `${import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}my-subscription`,
	},
	{
		name: 'Settings',
		icon: <Cog />,
		isExternal: true,
		href: `${import.meta.env.VITE_MAIN_WEBSITE_BASE_URL}settings`,
	},
];
