import { useState, useEffect } from 'react';
import { userManager } from '../../utils';

export const useUserManager = () => {
	const [token, setToken] = useState<string>('');

	const getToken = async () => {
		const user = await userManager.getUser();

		const token = user?.access_token;

		token && setToken(token);
	};

	useEffect(() => {
		getToken();
	}, []);

	return {
		token,
	};
};
