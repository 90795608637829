import { extendTheme } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { Button, Input, Checkbox, Progress, Menu } from './components';
import { colors } from './colors';
// import type { StyleFunctionProps } from '@chakra-ui/styled-system';

export const theme = extendTheme({
	colors,
	config: {
		initialColorMode: 'dark',
		useSystemColorMode: false,
	},
	components: {
		Button,
		Input,
		Select: Input,
		Checkbox,
		Progress,
		Menu,
	},
	shadows: {
		outline: '0 0 0 3px var(--chakra-colors-lightPrimary-400)',
	},
	styles: {
		global: (props: StyleFunctionProps) => ({
			body: {
				bg: mode(
					props.theme.semanticTokens.colors['chakra-body-bg']._light,
					'background',
				)(props),
			},
			borders: {
				borderWidth: '1px',
				borderRadius: '1px',
				borderStyle: 'solid',
				borderColor: '#FFFFFF20',
			},
		}),
	},
	fonts: {
		heading: 'Urbanist, sans-serif',
		body: 'Urbanist, sans-serif',
	},
});
