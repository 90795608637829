import React from 'react';
import {
	Collapse,
	Stack,
	Link as ChakraLink,
	HStack,
	Text,
	Center,
	Button,
} from '@chakra-ui/react';
import { FaDiscord } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { MobileMenuItem } from './MobileMenuItem';
import { mobileLinks } from '../../../../constants';
import { ReactComponent as QuestionIcon } from '../../../../assets/question.svg';
import { ReactComponent as LogOut } from '../../../../assets/log-out.svg';

interface IMobileMenuProps {
	show: boolean;
}

export const MobileMenu: React.FC<IMobileMenuProps> = ({ show }) => {
	const { signOutRedirect } = useAuth();
	return (
		<Collapse
			in={show}
			unmountOnExit={false}
			delay={0}
			style={{
				position: 'absolute',
				zIndex: 10,
				top: '80px',
				left: 0,
				width: '100%',
			}}>
			<Stack
				spacing={3}
				w="100%"
				align="center"
				direction={{ base: 'column', lg: 'row' }}
				bg={{ base: 'darkBlue2', lg: 'transparent' }}
				pb={{ base: '20px', lg: '0' }}
				px={{ base: '20px', md: 0 }}>
				{/* <HStack p="15px" bg="darkBlue4" mb="10px" w="100%">
					<QuestionIcon />
					<Text
						color="buttonPrimary"
						fontSize="18px"
						fontWeight={700}>
						Tutorial
					</Text>
				</HStack> */}
				<HStack
					p="15px"
					bg="darkBlue4"
					mb="10px"
					w="100%"
					as={ChakraLink}
					href="https://discord.gg/XNEmesgTFy"
					isExternal={true}>
					<Center
						boxSize="24px"
						borderRadius="50%"
						bg="buttonPrimary">
						<FaDiscord />
					</Center>
					<Text
						color="buttonPrimary"
						fontSize="18px"
						fontWeight={700}>
						Join Discord
					</Text>
				</HStack>
				{mobileLinks.map((elem, index) => (
					<MobileMenuItem
						key={index}
						title={elem.name}
						icon={elem.icon}
						isDisabled={elem.isDisabled}
						isExternal={elem.isExternal}
						href={elem.href}
					/>
				))}
				<Button
					onClick={() =>
						signOutRedirect({
							extraQueryParams: {
								returnUrl: `${
									import.meta.env.VITE_MAIN_WEBSITE_BASE_URL
								}`,
							},
						})
					}
					// p="10px 0 10px 10px"
					// height="40px"
					py={3}
					px={4}
					w="100%"
					justifyContent="flex-start"
					fontWeight={700}
					leftIcon={<LogOut />}
					color="white"
					bg="transparent"
					_hover={{ bg: '#161a31' }}>
					Log out
				</Button>

				{/* <MenuLink href="/gallery" title="Gallery" icon={<></>} />
        <MenuLink href="/pricing" title="Pricing" icon={<></>} /> */}
			</Stack>
		</Collapse>
	);
};
