import React from 'react';
import { Icon, Link, Tooltip, Image, Center, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { IconBaseProps, IconType } from 'react-icons';

interface INavItemProps {
	url?: string;
	icon: IconType | string;
	title: string;
	onClick?: () => void;
	isExternal?: boolean;
	elementClass?: string;
	iconProps?: IconBaseProps;
}

export const ActionItem: React.FC<INavItemProps> = ({
	url,
	icon,
	title,
	onClick,
	isExternal,
	elementClass,
	iconProps,
}) => (
	<Tooltip bg="textActive" color="white" label={title} placement="bottom">
		<Box
			onClick={onClick || undefined}
			as={onClick ? 'button' : undefined}
			bg="#11182B"
			borderRadius="50%"
			boxSize="24px"
			className={elementClass}
			_hover={{
				bg: '#1b2a52',
			}}>
			<Link
				as={isExternal || onClick ? undefined : RouterLink}
				href={onClick ? undefined : url}
				// @ts-ignore
				to={isExternal || onClick ? undefined : url}
				target={onClick || !isExternal ? undefined : 'blank'}>
				<Center boxSize="24px">
					{typeof icon === 'string' ? (
						<Image src={icon} width="20px" />
					) : (
						// @ts-ignore
						<Icon
							as={icon}
							{...iconProps}
							fontSize="xl"
							justifyContent="center"
						/>
					)}
				</Center>
			</Link>
		</Box>
	</Tooltip>
);
