import axios from 'axios';
import { ApiResponse } from '../types';
import interceptor from './interceptor';

type KeyValue<U> = {
	[key: string]: U;
};

interceptor();

const API = {
	get: async (url: string, params: any, headers: KeyValue<string> = {}) => {
		const response = await axios
			.get(url, {
				params,
				headers: {
					...headers,
				},
			})
			.catch(err => {
				return { data: err.response.data };
			});
		return response.data;
	},
	post: async (
		url: string,
		body: Record<string, any>,
		headers: KeyValue<string> = {},
	): Promise<ApiResponse<any>> => {
		const response = await axios
			.post(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => ({ data: err.response.data }));
		return response.data;
	},
	put: async (
		url: string,
		body: Record<string, any>,
		headers: KeyValue<string> = {},
	) => {
		const response = await axios
			.put(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => ({
				data: err.response.data,
			}));
		return response.data;
	},
	patch: async (
		url: string,
		body: Record<string, any>,
		headers: KeyValue<string> = {},
	) => {
		// const token = store.getState().auth.token.accessToken;
		// setAuthHeaderToken(token);
		const response = await axios
			.patch(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => ({
				data: err.response.data,
			}));
		return response.data;
	},
	delete: async (
		url: string,
		body?: Record<string, any>,
		headers: KeyValue<string> = {},
	) => {
		// const token = store.getState().auth.token.accessToken;
		// setAuthHeaderToken(token);
		const response = await axios
			.delete(url, {
				data: body,
				headers,
			})
			.catch(err => ({
				data: err.response.data,
			}));
		return response.data;
	},
};

// export default new Api();

export default API;
