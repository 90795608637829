import React from 'react';
import { Link as ChakraLink, HStack, chakra, Box } from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';

interface IMobileMenuItemProps {
	title: string;
	icon: JSX.Element;
	isDisabled?: boolean;
	isExternal?: boolean;
	href?: string;
}

export const MobileMenuItem: React.FC<IMobileMenuItemProps> = ({
	title,
	icon,
	isDisabled,
	href,
	isExternal,
}) => {
	const location = useLocation();
	const isActive = location.pathname === href;

	if (isDisabled) {
		return (
			<Box w={{ base: '100%', md: 'auto' }} py={3} px={4}>
				<HStack spacing="10px" color="textActive">
					{icon}
					<chakra.span fontWeight={700}>{title}</chakra.span>
				</HStack>
			</Box>
		);
	}

	return (
		<ChakraLink
			as={!isExternal ? Link : undefined}
			// @ts-ignore
			to={!isExternal ? href : undefined}
			isExternal={isExternal}
			href={href}
			color="white"
			bg={isActive ? 'lightBackground' : undefined}
			w={{ base: '100%', md: 'auto' }}
			borderRadius="10px"
			border={{
				base: isActive ? '2px solid' : undefined,
				md: 'none',
			}}
			borderColor="textActive"
			py={3}
			px={4}
			_hover={{
				bg: '#161a31',
			}}>
			<HStack spacing="10px">
				{icon}
				<chakra.span fontWeight={700}>{title}</chakra.span>
			</HStack>
		</ChakraLink>
	);
};
